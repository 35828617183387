import React, { useContext } from 'react'
import AppScreens from "../../assets/app_screens.png";
import { AlertContext } from '../../context/alert_state';
import SignIn from '../../alerts/SignIn';
import Login from '../../alerts/Login';
import { AuthUserContext } from '../../context/auth_user_state';
import { useNavigate } from 'react-router-dom';
import UserAlert, { UserAlertInterface } from '../../alerts/UserAlert';
import axios from 'axios';
import { baseUrl } from '../..';
import ConnectPayLogo from "../../assets/connect_pay.png"
import AppleLogo from "../../assets/new_apple.png"
import PlayStoreLogo from "../../assets/new_google.png"
const OnboardingNew = () => {
    const { alert, setAlert } = useContext(AlertContext);
    const { authUser, setAuthUser } = useContext(AuthUserContext)
    const navigate = useNavigate()
    async function fetchAlert() {
        const data = (await axios.get(baseUrl + "/alert/all")).data as UserAlertInterface
        if (data.showAlert) {
            if (setAlert) setAlert({ alertContent: <UserAlert {...data} />, showAlert: true, alertDismissible: !data.showCta })
        }
    }
    return (
        <div id='home' className='w-full h-[96vh] bg-new-img bg-center bg-cover pt-[100px] overflow-clip md:px-0 px-2'>
            <div className='w-full text-center pt-[10px]'>
                <p className='font-extralight md:text-2xl text-xl'>Introducing</p>
                <p className='font-extrabold md:text-[35px] text-[25px]'>Our Mobile App</p>
            </div>
            <div className='flex flex-col md:flex-row h-[83vh] lg:max-w-5xl  md:max-w-[540px] px-[15px] md:px-0 mx-auto'>
                {/* Section 1 */}
                <div className='md:basis-1/2 md:h-[83vh] h-[42vh] text-black flex flex-col gap-2 md:py-8 items-start'>
                    <div className='w-full md:h-[80px] h-[50px] flex md:justify-start items-start justify-center'>
                        <img src={ConnectPayLogo} className='h-full object-contain' alt='ConnectPay Logo' />
                    </div>
                    <p className='py-[5px] md:text-[16px] text-[12px] md:text-start text-center'>An app that streamlines all your bill payments in one convenient place.</p>
                    <p className='font-bold md:text-[16px] text-[14px]'>Please note: Exisiting website users would be required to create a NEW ACCOUNT in the App.</p>
                    <div className='flex gap-x-3'>

                        <a href='https://play.google.com/store/apps/details?id=com.connectpay.app&pcampaignid=web_share' target='_blank' rel='noreferrer'>
                            <div className='px-[10px] py-2 bg-black  flex gap-x-2 text-white  items-center rounded-lg overflow-clip' >
                                <div className='w-[30px] h-[30px]'>


                                    <img src={PlayStoreLogo} alt='PlayStore Logo' className='object-contain' />
                                </div>
                                <div>
                                    <p className='text-xs'>Get on</p>
                                    <p className='font-bold md:text-[16px] text-[12px]'>Play Store</p>
                                </div>
                            </div>
                        </a>
                        <a rel='noreferrer' target='_blank' href='https://apps.apple.com/us/app/connectpay-mobile/id6633444026'>
                            <div className='px-[10px] py-2 bg-black  flex gap-x-2 text-white items-center rounded-lg overflow-clip' >
                                <div className='w-[30px] h-[30px]'>


                                    <img src={AppleLogo} alt='Apple Logo' className='object-contain' />
                                </div>
                                <div>
                                    <p className='text-xs'>Get on</p>
                                    <p className='font-bold md:text-[16px] text-[12px]'>App Store</p>
                                </div>
                            </div>
                        </a>


                    </div>
                    <p className=' py-3 border-[2px] border-yellow w-52 mt-2 md:text-base text-sm text-center text-black rounded-md font-semibold cursor-pointer' onClick={(e) => {
                        if (authUser) {
                            navigate("/dashboard")
                            fetchAlert()
                            return
                        }
                        if (setAlert)
                            setAlert({ alertContent: <Login />, showAlert: true, alertDismissible: false });
                    }} >or Continue on Web</p>
                </div>
                <div className='w-[50px] hidden md:block'></div>
                {/* Section 2 */}
                <div className='md:basis-1/2  md:h-[100vh] h-[51vh] w-full'>
                    <img className='w-full h-full object-contain' src={AppScreens} alt='app_phone' />
                </div>

            </div>
        </div>
    )
}

export default OnboardingNew